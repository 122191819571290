import React, { useState, useEffect } from "react";
import SectionHeader from "../sectionHeader";
 
import ReactMarkdown from "react-markdown";
import "./keyFeatures.scss";

import { Tab, Tabs } from "react-bootstrap";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {View, Text, StyleSheet, ScrollView, TouchableHighlight} from 'react'; 


// newly added

function KeyFeatures(props) {
  const [key, setKey] = useState("applications");

  const hasClass = (element, className) => {
    return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
  };

  const clearSelection = (navItems) => {
    navItems.forEach((nav) => {
      if (hasClass(nav, "active")) {
        nav.classList.remove("active");
      }
    });
  };

  const TabClicked = (val) => {
    const tab = `#controlled-tab-example-tabpane-${val.clickHandler}`;
    const tabContent = document.querySelector(".tab-content");
    const tabSelected = tabContent.querySelector(tab);
    tabContent.prepend(tabSelected);

    const navPills = document.querySelector(".nav-pills");
    const activePill = navPills.querySelector(".active");
    if (activePill !== null) {
      activePill.classList.remove("active");
    }

    const pill = `#controlled-tab-example-tab-${val.clickHandler}`;
    const pillContent = document.querySelector(pill);
    pillContent.classList.add("active");

    // setKey(val)
  };
/*
  useEffect(() => {
    const tabContent = document.querySelector(".tab-content");
    const activeTab = tabContent.querySelector(".tab-pane.active");
    const navItems = document.querySelector(".nav-pills").querySelectorAll("*");

    navItems.forEach((nav) => {
      nav.addEventListener("mouseover", (e) => {
        clearSelection(navItems);
        const id = e.toElement.id;

        if (id !== null) {
          e.toElement.classList.add("active");

          const tabID = id.split("-")[4];
          const tab = `#controlled-tab-example-tabpane-${tabID}`;
          const tabContent = document.querySelector(".tab-content");
          const tabSelected = tabContent.querySelector(tab);
          tabContent.prepend(tabSelected);
        }
      });
    });

    tabContent.prepend(activeTab);
  }, key);
 */
 
  
  return (
    <div className="key-features">
      
      

      <div>
      {/* <Application scroll={(null,1)}/> */}
      <div>     
        {/* <a className="prev" onClick={() => scroll1(320)}>&#10094;</a> */}
         </div>
       <div id="container" className="row">
       {props.data.Tab.map((item) => {
             const clickHandler = item.EventKey;
            
          
             return (
               <div className="col">
                   <div
                   className="enrol-features__card xyz-in"
                   xyz="fade right stagger duration-8"
                 >
                     
                  
                  <ReactMarkdown source={item.Header2} />
 
                  <ReactMarkdown source={item.Header3} />

                 
 
                   
                 </div>
                 </div>
               
             );
             
           })}
           </div>
           <div>
        {/* <a className="next" onClick={() => scroll2(320)}>&#10095;</a> */}
    </div>
           {/* <Application1 scroll={(null,1)}/> */}
       </div>
       </div>
       
     
   );
 }
 
 export default KeyFeatures;
 